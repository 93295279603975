import './App.css';
import './pages-styling/sheets-of-paper-a4.css'
import { useEffect, useState } from 'react';

function App() {
  const [invoice, setInvoice] = useState(null)

  useEffect(() => {
    if (!document.getElementById("App").getAttribute("listenerAttached")) {
      window.addEventListener("message", (event) => {

        // IMPORTANT: check the origin of the data!
        if (event.origin.startsWith("https://admin.takeaseat.io") || event.origin.startsWith("http://localhost:3000")) {
          // The data was sent from your site.
          // Data sent with postMessage is stored in event.data:
          if (event.data.instruction === "print") window.print()
          if (event.data.instruction === "update") {
            console.log(event.data.content)
            setInvoice(JSON.parse(event.data.content))
          }
        } else {
          return;
        }
      });
      document.getElementById("App").setAttribute("listenerAttached", true)
    }
  }, [])

  return (
    <div id="App">
      {invoice && <>
        <div className='invoice-header'>
          <div className='name'>{invoice.header.name}</div>
          <br />
          <div className='contact'>
            <div className='address'>{invoice.header.contact.address.map(line => <div>{line}</div>)}</div>
            <div className='phone'>{invoice.header.contact.phone}</div>
          </div>
        </div>
        <div className='invoice-body'>
          <div className='title'>
            <span>Invoice #</span>
            <span>{invoice.body['invoice number']}</span>
            {invoice.payment_status === "paid" && <div style={{
              position: "absolute",
              right: "15px",
              top: "-15px",
              fontSize: "150%",
              color: "red",
              border: "8px solid",
              padding: "0 16px",
              transform: "rotate(-20deg)",
              background: "white",
              fontFamily: "sans-serif"
            }}>
              PAID
            </div>}
          </div>
          <div className='gen-date'>{invoice.body['creation date']}</div>
          <br />
          <br />
          <div className='to-row'>
            <div className='to'>
              <div className='title'><span>Bill To</span></div>
              <div>{invoice.body.to.name}</div>
              <div>{invoice.body.to.address.map(line =>
                <div>{line}</div>
              )}</div>
            </div>
            <div className='for'>
              <div className='title'><span>For</span></div>
              <div>{invoice.body['service invoiced'].map(ele => <div>{ele}</div>)}</div>
            </div>
          </div>
          <br />
          <div className='items'>
            <table>
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Quantity</th>
                  <th>Unit price</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoice.body['detailed items'].map(item =>
                  <tr>
                    <td>{item.description || ""}</td>
                    <td>{item.quantity || ""}</td>
                    <td>{item['unit price'] || ""}</td>
                    <td>{item['currency ISO']}&#160;{item.amount || ""}</td>
                  </tr>
                )}
              </tbody>
              <tr className="blank_row">
                <td colspan="4">&#160;</td>
              </tr>
              <tfoot>
                {invoice.body.total?.subtotal != undefined && <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td>{invoice.body.total['currency ISO']}&#160;{invoice.body.total?.subtotal}</td>
                </tr>}
                {invoice.body.total?.['tax rate'] != undefined && <tr>
                  <td>Tax rate</td>
                  <td></td>
                  <td></td>
                  <td>{invoice.body.total?.["tax rate"]}</td>
                </tr>}
                {invoice.body.total?.['total'] != undefined && <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{invoice.body.total['currency ISO']}&#160;{invoice.body.total?.["total"]}</td>
                </tr>}
              </tfoot>
            </table>
          </div>
          <br />
          <br />
          <div className='payment-instructions'>{invoice.body['payment instructions'].map(ele => <div>{ele}</div>)}</div>
          <br />
          <br />
          <div>{invoice.body.footer}</div>
        </div>
      </>}
    </div>
  );
}

export default App;
